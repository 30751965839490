import React from "react"
import Layout from "../../layout"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import Video from "../../components/Video"
import Introduction from "../../components/Introduction"
import Carousel from "../../components/Carousel"
import VideoDetail from "../../components/VideoDetail"
import SpecsTable from "../../components/SpecsTable"
import FormContact from "../../components/FormContact"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CarouselMobile from "../../components/Carousel/mobile"
import FeatureLineMobile from "../../components/FeatureLine/mobile"
import VideoDetailMobile from "../../components/VideoDetail/mobile"
import FeatureLine from "../../components/FeatureLine"

// ASSETS
import FrioVideo from "../../assets/videos/Frio/frio.mp4"
import FrioVideoPreview from "../../assets/videos/Frio/frio-preview.mp4"
import FrioDetailVideo from "../../assets/videos/Frio/frio-detail-1.mp4"
import FrioDetailVideoMobile from "../../assets/videos/Frio/mobile/frio-detail-mobile-1.mp4"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

const IndexPage = ({ location, data }) => {
  const {t} = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language

  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
    >
      <SEO
        pathname={location.pathname}
        title={t('meta', { returnObjects: true })['title']}
        keywords={t('meta', { returnObjects: true })['keywords']}
        description={t('meta', { returnObjects: true })['description']}
        img={t('meta', { returnObjects: true })['image']}
        imageHeight="628"
        imageWidth="1200"
        lang={activeLanguage}
      />
      <Video
        className="full-width full-height scrollable"
        srcp={FrioVideo}
        src={FrioVideoPreview}
        poster="https://ramalhos.com/video_posters/Frio/frio.jpg"
        width="100%"
        id="video-frio"
        imgMobile={t('video', { returnObjects: true })['bgMobile']}
        fullString={t('videoHome', { returnObjects: true })['info']}
        toClick="#introduction"
      />

      <Introduction
        className="scrollable"
        id="introduction"
        translation={t('introduction', { returnObjects: true })}
        blue
      />

      {/* IF MOBILE */}
      {breakpoints.tl ? (
        <CarouselMobile
          showArrows={false}
          showIndicators={true}
          autoPlay={false}
          transitionTime={0}
          id="carousel-estufa-mobile"
          carouselContent={t('carousel', { returnObjects: true })}
          blue
        />
      ) : (
        //IF NOT
        <Carousel
          showArrows={false}
          showIndicators={true}
          autoPlay={false}
          transitionTime={0}
          id="carousel-estufa"
          carouselContent={t('carousel', { returnObjects: true })}
          blue
        />
      )}

      {/* DESKTOP  */}

      {!breakpoints.tl && (
        <>
          <FeatureLine
            id="feature-line-frio"
            _title={t('feature', { returnObjects: true })['title1']}
            text={t('feature', { returnObjects: true })['text1']}
            image={t('feature', { returnObjects: true })['image1_1']}
            positionCenter
            midBrightness
            blue
          />

          <VideoDetail
            src={FrioDetailVideo}
            poster="https://ramalhos.com/video_posters/Frio/frio-detail-1.jpg"
            id="video-detail-frio"
            _title={t('video', { returnObjects: true })['title1']}
            text1={t('video', { returnObjects: true })['text1_1']}
            blue
          />

          <FeatureLine
            id="feature-line-frio-1"
            _title={t('feature', { returnObjects: true })['title2']}
            height="unset"
            paddingTop="100px"
            noImage
            noBar
          />
        </>
      )}

      {/* --- */}

      {/* MOBILE */}

      {breakpoints.tl && (
        <>
          <FeatureLineMobile
            id="first-line-mobile"
            _title={t('feature', { returnObjects: true })['title1']}
            text1={t('feature', { returnObjects: true })['text1']}
            image={t('feature', { returnObjects: true })['image1_1M']}
            positionTopBottom
            blue
          />

          <VideoDetailMobile
            src={FrioDetailVideoMobile}
            poster="https://ramalhos.com/video_posters/Frio/mobile/frio-detail-mobile-1.jpg"
            id="video-detail-frio-mobile"
            _title={t('video', { returnObjects: true })['title1']}
            text={t('video', { returnObjects: true })['text1_1']}
            positionAbsolute
            videoOpacity
            blue
          />

          <VideoDetailMobile
            id="title-frio"
            _title={t('feature', { returnObjects: true })['text2']}
            smallHeight
          />
        </>
      )}

      {/* --- */}

      <SpecsTable
        id="spec-table"
        specsTable={t('specs', { returnObjects: true })['content']}
        specsLength={t('specs', { returnObjects: true })['content'].length}
        location={location}
        svgWidth="190px"
        blue
      />

        {!(activeLanguage === 'br') &&
            <FormContact 
                id="form-contact" 
                info={t('form', { returnObjects: true })}
                lang={activeLanguage} 
                location={location}
            />
        }
    </Layout>
  )
}

export default IndexPage

export const QueryFrioPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "frio", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
